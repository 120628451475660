import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducer from "./Reducer";
import { combineReducers } from "redux-immer";
import produce from "immer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// import customHistory from './customHistory';
const persistConfig = {
  key: "credence",
  storage: storage,
};
const rootReducer = combineReducers(produce, {
  counters: reducer
});
const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
// const store = createStore(pReducer, applyMiddleware(thunk)
//   // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );
const persister = persistStore(store);

// window.store = store;

export  {persister, store};
